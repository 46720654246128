/*
-------------------------------
ROOT
-------------------------------
*/

* {
    padding: 0;
    margin: 0;
}

label {
    color: #002D62 !important;
}

.card-title {
    color: #002D62 !important;
}

input {
    color: #002D62 !important;
}

html {
    font-family: "TheSans Veolia", Roboto, sans-serif;
    position: relative;
    min-height: 100%;
}

body {
    color: #002D62;
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}

:root {
    /*
    * COlORS
    */

    /* background colors */
    --bg-light-white: hsl(0, 0%, 100%);
    --bg-light-gray: hsl(0, 0%, 90%);
    --bg-dark-gray: rgb(170, 170, 170);

    /*
    * Text Color
    */

    --text-white: hsla(0, 0%, 100%, 1);
    --text-black: hsla(0, 0%, 0%, 1);
    --text-sunset: rgb(236, 118, 46);

    /*
    * Border color
    */

    --border-gray: rgb(204, 204, 204);

    /*
    * TYPOGRAPHY
    */

    /* font family */

    --fontFamily-open-sans: 'Open Sans', sans-serif;

    /* font size */
    --fontSize-1: 4rem;
    --fontSize-2: 3.2rem;
    --fontSize-3: 2.4rem;
    --fontSize-4: 2rem;
    --fontSize-5: 1.8rem;
    --fontSize-6: 1.6rem;
    --fontSize-7: 1.4rem;

    /* font weight */
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-semiBold: 600;
    --weight-bold: 700;

    /* spacing */

    --section-spacing: 100px;

    /* border radius */

    --radius-24: 24px;
    --radius-40: 40px;

}

.display-7 {
    font-size: calc(1.0rem + 0.8vw);
    font-weight: 300;
    line-height: 1.2;
}

.btn-success:not(:disabled) {
    pointer-events: auto;
    /* Ensure buttons are clickable */
}


.btn-secondary {
    background-color: #122A5D;
    color: #ffffff;
    border: none;
    border-radius: 0.5rem;
    font-weight: 700;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    display: inline-block;
}


.btn-secondary:hover {
    border: 1px solid #122A5D;
    color: black;
    background-color: #ffffff;
    /* Slightly darker blue on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    /* Enhanced shadow on hover */
}


.btn-secondary:focus {
    outline: none;
    /* Remove default outline */
    box-shadow: 0 0 0 3px rgba(18, 42, 93, 0.5);
    /* Accessible focus ring */
}

.btn-secondary:disabled {
    background-color: #6c757d;
    /* Gray color for disabled state */
    cursor: not-allowed;
    /* Prevent interaction */
    box-shadow: none;
    /* Remove shadow for disabled state */
}

.navbar {
    background-color: #333;
    padding: 0.5rem 1rem;
}

.navbar-brand img {
    height: 50px;
}

/* Dropdown Button (Styled as Green) */
.dropdown-toggle {
    color: #fff !important;
    background-color: #438D42 !important;
    border: none;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus {
    background-color: #438D42 !important;
}

/* Dropdown Menu */
.dropdown-menu {
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.dropdown-menu .dropdown-item {
    color: #333;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #e9ecef;
}

/* General Navbar Links */
.nav-link {
    color: #fff;
    font-weight: 600;
    margin-right: 1.5rem;
}

.nav-link:hover {
    color: #ccc;
}

/* Icons */
.bi {
    vertical-align: middle;
}

/* Bookmarks Badge */
.badge {
    font-size: 0.75rem;
}

/* Button Styling */
.btn-green {
    background-color: #438D42;
    border: 1px solid #438D42;
    color: white;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: background-color 0.3s, color 0.3s;
}

.btn-green:hover {
    color: black;
    background-color: white;
    border-color: #438D42;
}

.btn-green:active {
    background-color: #327e33;
    border-color: #327e33;
    color: white;
}

.dropdown-menu {
    padding: 0;
    border-radius: 0.25rem;
    max-width: 200px;
    overflow: hidden;
}

.dropdown-item {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    width: 100%;
}

.dropdown-item:hover {
    background-color: #d1e7dd;
    border-radius: 0;
    /* Ensure it doesn't spill over */
}

.navbar-toggler {
    background-color: white !important;
    color: white !important;
}

/*
-------------------------------
LOGOS / IMGS
-------------------------------
*/

.header-logo {
    padding: 10px;
    width: 200px;
    height: 40px;
}

/*
-------------------------------
EFFECTS
-------------------------------
*/

.dropdown-menu.show {
    width: 100%;
}

.header-styles {
    min-height: 90px;
    background-color: #2C2C2C;
}

.hideItem {
    display: none !important;
}

.alert {
    margin-bottom: 0px !important;
}

.read-only-message {
    background-color: rgba(247, 120, 71, 0.2);
    /* Semi-transparent blue background */
    padding: 13px;
    width: 100%;
    border-radius: 5px;
    color: #333;
    font-size: 16px;
    margin: 0 auto;
    text-align: center;
}

.disabled-element {
    pointer-events: none !important;
    cursor: not-allowed !important;
}

.navbar-nav>ul>li>a :hover {
    color: red;
}

a {
    all: unset;
    cursor: pointer;
}

a::hover,
a::focus {
    all: unset;
    color: inherit;
    cursor: pointer;
}

.work-link:hover a {
    color: #438D42;
}

.p-5 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.numberCircle {
    color: #ec8f21;
    background-color: rgb(245, 244, 244);
    width: 75px;
    line-height: 75px;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
}

.card-title {
    font-weight: bold;
}

.nav-item.active a {
    color: #e02327 !important;
}

.nav-link:hover {
    color: #438D42 !important;
}

.modal-content {
    background-color: #E6E6E6 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}

#loader-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* semi-transparent background */
    z-index: 99998;
    /* slightly lower z-index than loader */
}

#loader {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 20px solid rgb(219, 8, 8);
    border-radius: 50%;
    border-top: 20px solid rgb(196, 196, 196);
    width: 150px;
    height: 150px;
    animation: spinner 4s linear infinite;
    z-index: 99999;
}

.active-btn {
    background-color: #002D62 !important;
    color: white !important;
}

.sub-btn {
    color: rgb(0, 0, 0);
    background-color: rgb(229, 229, 229);
}

.sub-btn:hover {
    background-color: rgb(170, 170, 170) !important;

}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}




/*
-------------------------------
PAGINATION
-------------------------------
*/

.pagination>li>a,
.pagination>li>span {
    color: rgb(0, 0, 0);
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: rgb(0, 0, 0);
    border-color: rgb(255, 255, 255);
}


/*
-------------------------------
TABLES
-------------------------------
*/
td {
    color: #002D62 !important
}

tr {
    border: 3px solid white !important;
}

.table-child tr {
    border: none;
}

tbody>tr {
    background-color: #E5E5E5;
}

.tableIcons {
    font-size: 1.5rem;
}

.table {
    margin: 0px !important;
    text-align: center;
    --bs-table-color: inherit !important;
    --bs-table-bg: inherit !important;
}

.table>tbody>tr>td {
    vertical-align: middle;
}


td {
    align-items: center !important;
    text-align: center;
    height: 4rem !important;
}

.bi {
    cursor: pointer;
}

.dashboard-card .bi.bi-lock2 {
    font-size: 21px;
}

th {
    align-items: center !important;
    text-align: center;
}

/*
-------------------------------
CARDS
-------------------------------
*/
.card {
    overflow: hidden;
    border-radius: 0% 10% 10% 10% !important;
    border-width: 3px !important;
}

.card-margin {
    margin-top: 5rem !important;
}

.card-footer {
    background-color: #D7D6D7 !important;
}

.anchor-text {
    font-weight: 800;
}

.dashboard-card {
    width: 14rem;
    height: 12.5rem;
    background-color: white;
    margin-bottom: 30px;
}


.expand .icon {
    display: inline-block;
    padding: 0.1em 0.1em;
    border: 2px solid #cbcdcf;
    border-radius: 0.3em;
    background-color: rgb(0, 0, 0);
    color: #FFF;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.expand .icon:hover {
    background-color: rgb(36, 36, 36);
    border-color: #ffffff;
}

/*
-------------------------------
MY ACCOUNT CSS
-------------------------------
*/

.enquire {
    width: 45%;
    background: white;
}

.form {
    width: 700px;
}

.myform {
    border-radius: 10px;
    width: 100%;
    background: #D6D6D6;
    padding: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px
}

.enquire-wrapper {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.enquire {
    padding: 10px;
}

.enquire-wrapper>.button {
    margin-top: 10px;
    margin-left: 20px;
    justify-content: start !important;
}


.wrapper {
    padding: 30px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1px;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    justify-items: stretch;

}

.myform label {
    display: block;
    color: #000000;
    font-weight: bold;
    font-size: .9rem;
    margin-bottom: 10px;
}

.myform>.control-from>input {
    border: 0;
    outline: 0;
    height: 50px;
    background: #FFF;
    width: 100%;
    color: #000000;
    font-size: .9rem;
    text-indent: 15px;
    border: 2px solid transparent;
    transition: border 250ms;
}

.required::after {
    content: "*";
    color: red;
}

.requiredBrackets {
    color: red;
}


.full-width {
    grid-column: -1 / 1
}

.address {
    font-size: 12px !important;
}

.button {
    grid-column: -1 / 1;
    display: flex;
    justify-content: flex-end;
}

.addnewUser {
    cursor: pointer;
}

.addnewUser:hover p {
    color: #438D42;
    background-color: #ffffff;
    /* Slightly darker blue on hover */
}

/*
-------------------------------
FOOTER CSS
-------------------------------
*/
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    background-color: #ffffff;
    margin: auto;
    color: #000000;
    text-align: center;
}

/* Show html content - links to hiding html in header */

html {
    visibility: visible;
    opacity: 1;
}

/*
-------------------------------
MEDIA QUERIES
-------------------------------
*/
@media only screen and (max-width: 600px) {
    .loginBanner {
        content: url("/img/loginBannerSmall.png")
    }
}

@media only screen and (max-width: 1900px) {
    .subconParty {
        margin-left: 3rem !important;
    }
}
